<template>
  <v-card>
    <v-toolbar>
      <v-toolbar-title>{{ $i18n.translate("Sale Type") }} - {{ form.name }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tooltip bottom v-if="$privilege.hasPrivilege('SALE_TYPE_UPDATE') && isEditing">
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="mr-2" fab small @click="onSubmit" v-bind="attrs" :disabled="!valid" v-on="on">
            <v-icon>mdi-content-save</v-icon>
          </v-btn>
        </template>
        <span>{{ $i18n.translate("Save") }}</span>
      </v-tooltip>
      <v-tooltip bottom v-if="$privilege.hasPrivilege('SALE_TYPE_UPDATE') && !isNew">
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="mr-2" fab small @click="onEdit" v-bind="attrs" v-on="on">
            <v-icon v-if="isEditing">mdi-close</v-icon>
            <v-icon v-else>mdi-pencil</v-icon>
          </v-btn>
        </template>
        <span v-if="isEditing">{{ $i18n.translate("Cancel") }}</span>
        <span v-else>{{ $i18n.translate("Edit") }}</span>
      </v-tooltip>

      <template v-slot:extension>
        <v-tabs v-model="tab">
          <v-tab> {{ $i18n.translate("Sale Type") }} Details </v-tab>
        </v-tabs>
      </template>
    </v-toolbar>
    <v-card-text>
      <ApiError :errors="errors"></ApiError>

      <div class="text-center" v-if="isBusy">
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </div>
      <v-form :value="valid" @submit.prevent="onSubmit" v-if="!isBusy" v-model="valid">
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-container>
              <v-row>
                <v-col cols="6">
                  <KeyField
                    name="saleTypeKey"
                    label="Key"
                    id="saleTypeKey"
                    v-model="form.saleTypeKey"
                    :disabled="!isEditing"
                    :error-messages="$error.getValidationError(errors, 'saleTypeKey')"
                    @input="$error.clearValidationError(errors, 'saleTypeKey')"
                  ></KeyField>
                  <v-text-field
                    name="name"
                    label="Name"
                    id="name"
                    v-model="form.name"
                    :rules="rules.name"
                    :disabled="!isEditing"
                    :error-messages="$error.getValidationError(errors, 'name')"
                    @input="$error.clearValidationError(errors, 'name')"
                  ></v-text-field>
                  <v-text-field
                    name="description"
                    label="Description"
                    id="description"
                    v-model="form.description"
                    :disabled="!isEditing"
                    :error-messages="$error.getValidationError(errors, 'description')"
                    @input="$error.clearValidationError(errors, 'description')"
                  ></v-text-field>
                  <v-switch
                    name="allowSaleAmount"
                    label="Allow Sale Amount"
                    id="allowSaleAmount"
                    v-model="form.allowSaleAmount"
                    :disabled="!isEditing"
                    :error-messages="$error.getValidationError(errors, 'allowSaleAmount')"
                    @input="$error.clearValidationError(errors, 'allowSaleAmount')"
                  />
                  <DateTimePickerField
                    v-model="form.effectiveDate"
                    :disabled="!isEditing"
                    :label="$i18n.translate('Effective Date')"
                    defaultTime="00:00"
                  ></DateTimePickerField>
                  <DateTimePickerField
                    v-model="form.expirationDate"
                    :disabled="!isEditing"
                    :label="$i18n.translate('Expiration Date')"
                    defaultTime="23:59"
                  ></DateTimePickerField>
                </v-col>
                <v-col cols="6"> </v-col>
              </v-row>
            </v-container>
          </v-tab-item>
        </v-tabs-items>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import ApiError from "../../display/ApiError.vue";
import DateTimePickerField from "../../fields/DateTimePickerField.vue";
import KeyField from "../../fields/KeyField.vue";

export default {
  components: { ApiError, KeyField, DateTimePickerField },
  name: "SaleType",
  metaInfo: {
    title: "Sale Type"
  },
  data: function() {
    return {
      saleTypeId: 0,
      tab: null,
      valid: false,
      isNew: false,
      isEditing: false,
      isBusy: false,
      form: {},
      errors: {},
      rules: {
        name: [v => !!v || "Name is required"]
      }
    };
  },
  methods: {
    onSubmit() {
      this.isBusy = true;
      this.errors = {};

      let serviceCall = null;

      if (this.isNew) {
        serviceCall = this.$api.post("/api/saleTypes", this.form);
      } else {
        serviceCall = this.$api.patch("/api/saleTypes/" + this.$route.params.id, this.form);
      }
      serviceCall
        .then(({ data }) => {
          this.form = data;
          this.breadcrumb();
          this.isBusy = false;
          this.isEditing = false;
          if (this.isNew) {
            this.isNew = false;
            this.$route.params.id = data.id;
          }
          this.saleTypeId = data.id;
          this.fetchData();
        })
        .catch(error => {
          this.isBusy = false;
          this.isEditing = true;
          this.errors = this.$api.getErrorsFromResponse(error);
        });
    },
    onEdit() {
      this.isEditing = !this.isEditing;
      if (!this.isEditing) {
        this.fetchData();
      }
    },
    fetchData() {
      if (!this.isNew) {
        this.isBusy = true;
        this.$api.get("/api/saleTypes/" + this.$route.params.id).then(({ data }) => {
          this.isBusy = false;
          this.form = data;
          this.breadcrumb();
        });
      }
    },
    breadcrumb() {
      if (this.isNew) {
        this.$store.dispatch("setBreadcrumb", [
          {
            text: this.$i18n.translate("Dashboard"),
            to: { name: "dashboard" },
            exact: true
          },
          {
            text: this.$i18n.translate("Clients"),
            to: { name: "clients" },
            exact: true
          },
          {
            text: this.selectedClient.name,
            to: {
              name: "client",
              params: { id: this.selectedClient.id },
              exact: true
            }
          },
          {
            text: this.$i18n.translate("Programs"),
            to: { name: "programs" },
            exact: true
          },
          {
            text: this.selectedProgram.name,
            to: {
              name: "program",
              params: { id: this.selectedProgram.id },
              exact: true
            }
          },
          {
            text: this.$i18n.translate("Sale Types"),
            to: { name: "saleTypes" },
            exact: true
          },
          { text: "New " + this.$i18n.translate("Sale Type") }
        ]);
      } else {
        this.$store.dispatch("setBreadcrumb", [
          {
            text: this.$i18n.translate("Dashboard"),
            to: { name: "dashboard" },
            exact: true
          },
          {
            text: this.$i18n.translate("Clients"),
            to: { name: "clients" },
            exact: true
          },
          {
            text: this.selectedClient.name,
            to: {
              name: "client",
              params: { id: this.selectedClient.id },
              exact: true
            }
          },
          {
            text: this.$i18n.translate("Programs"),
            to: { name: "programs" },
            exact: true
          },
          {
            text: this.selectedProgram.name,
            to: {
              name: "program",
              params: { id: this.selectedProgram.id },
              exact: true
            }
          },
          {
            text: this.$i18n.translate("Sale Types"),
            to: { name: "saleTypes" },
            exact: true
          },
          { text: this.form.name }
        ]);
      }
    }
  },
  computed: {
    ...mapGetters(["selectedClient", "selectedProgram"]),
    title() {
      return "Sale Type - " + this.form.name;
    }
  },

  mounted() {
    this.saleTypeId = parseInt(this.$route.params.id);
    if (this.$route.params.id == 0) {
      this.isNew = true;
      this.isEditing = true;
      this.form.program = this.$api.getSelfUrl("programs", this.selectedProgram);
      this.breadcrumb();
    }

    this.fetchData();
  }
};
</script>
